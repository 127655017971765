import Badge from './badge/badge.vue'
import Button from './button/button.vue'
import Checkbox from './checkbox/checkbox.vue'
import Dialog from './dialog/dialog.vue'
import Input from './input/input.vue'
import Progress from './progress/progress.vue'
import Radio from './radio/radio.vue'
import Select from './select/select.vue'
import Textarea from './textarea/textarea.vue'

export default {
  install (app) {
    app.component('badge-element', Badge)
    app.component('button-element', Button)
    app.component('checkbox-element', Checkbox)
    app.component('dialog-element', Dialog)
    app.component('input-element', Input)
    app.component('progress-element', Progress)
    app.component('radio-element', Radio)
    app.component('select-element', Select)
    app.component('textarea-element', Textarea)
  }
}
