<template>
  <transition name='progress'>
    <div class='progress'
      v-show='active'>
      <svg class='spn'
        viewBox='0 0 16 16'>
        <circle class='rin'
          cx='8'
          cy='8'
          r='7'
          fill='none'
          stroke-width='2'
          stroke-linecap='round'/>
      </svg>
    </div>
  </transition>
</template>

<script>
  export default {
    props: {
      active: Boolean
    }
  }
</script>

<style lang='scss' scoped>
  @use 'sass:map';
  @use 'sass:math';
  @use '@/styles/variables';

  .progress {
    display: inline-flex;

    &-enter-active,
    &-leave-active {
      transition: transform 0.2s ease-in-out;
    }

    &-enter-from,
    &-leave-to {
      transform: scale(0);
    }
  }

  .spn {
    width: 16px;
    height: 16px;
  }

  .rin {
    transform-origin: center;
    animation: shrink 4s linear infinite, fade 16s linear infinite;
    stroke-dasharray: 2 * math.$pi * 7;
  }

  @keyframes shrink {
    0% {
      transform: rotate(0);
      stroke-dashoffset: 2 * math.$pi * 7 / 3;
    }

    50% {
      transform: rotate(720deg);
      stroke-dashoffset: 2 * math.$pi * 7;
    }

    100% {
      transform: rotate(1080deg);
      stroke-dashoffset: 2 * math.$pi * 7 / 3;
    }
  }

  @keyframes fade {
    0% {
      stroke: map.get(variables.$colors, 'red', '500');
    }

    25% {
      stroke: map.get(variables.$colors, 'yellow', '500');
    }

    50% {
      stroke: map.get(variables.$colors, 'green', '500');
    }

    75% {
      stroke: map.get(variables.$colors, 'blue', '500');
    }

    100% {
      stroke: map.get(variables.$colors, 'red', '500');
    }
  }

  @media (min-width: map.get(variables.$breakpoints, 'lg')) {
    .spn {
      width: 20px;
      height: 20px;
    }
  }
</style>
