<template>
  <button class='button'
    :class='{
      "is-rounded": rounded,
      "is-outlined": outlined,
      [`is-${color}`]: color
    }'
    :disabled='disabled'>
    <slot/>
  </button>
</template>

<script>
  export default {
    props: {
      rounded: Boolean,
      outlined: Boolean,
      color: String,
      disabled: Boolean
    }
  }
</script>

<style lang='scss' scoped>
  @use 'sass:map';
  @use '@/styles/variables';

  .button {
    display: inline-flex;
    align-items: center;
    padding: 0 24px;
    border: 2px solid transparent;
    height: 40px;
    box-sizing: border-box;
    color: white;
    background-color: map.get(variables.$colors, 'primary', '500');
    border-radius: 4px;
    font-weight: map.get(variables.$font-weights, 'bold');
    white-space: nowrap;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;

    &:hover {
      border-color: map.get(variables.$colors, 'primary', '500');
      color: map.get(variables.$colors, 'primary', '500');
      background-color: transparent;
    }

    &:disabled {
      border-color: transparent;
      color: map.get(variables.$colors, 'gray', '500');
      background-color: map.get(variables.$colors, 'gray', '300');
    }

    &.is-rounded {
      border-radius: 20px;
    }

    &.is-outlined {
      border-color: map.get(variables.$colors, 'primary', '500');
      color: map.get(variables.$colors, 'primary', '500');
      background-color: transparent;

      &:hover {
        color: white;
        background-color: map.get(variables.$colors, 'primary', '500');
      }

      &:disabled {
        border-color: map.get(variables.$colors, 'gray', '300');
        color: map.get(variables.$colors, 'gray', '500');
        background-color: transparent;
      }
    }

    @each $name, $color in variables.$colors {
      &.is-#{$name} {
        background-color: map.get($color, '500');

        &:hover {
          border-color: map.get($color, '500');
          color: map.get($color, '500');
          background-color: transparent;
        }

        &:disabled {
          border-color: transparent;
          color: map.get(variables.$colors, 'gray', '500');
          background-color: map.get(variables.$colors, 'gray', '300');
        }

        &.is-outlined {
          border-color: map.get($color, '500');
          color: map.get($color, '500');
          background-color: transparent;

          &:hover {
            color: white;
            background-color: map.get($color, '500');
          }

          &:disabled {
            border-color: map.get(variables.$colors, 'gray', '300');
            color: map.get(variables.$colors, 'gray', '500');
            background-color: transparent;
          }
        }
      }
    }
  }

  @media (min-width: map.get(variables.$breakpoints, 'lg')) {
    .button {
      padding: 0 32px;
      height: 48px;

      &.is-rounded {
        border-radius: 24px;
      }
    }
  }
</style>
