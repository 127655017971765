import firebase from '@/firebase'
import axios from '@/axios'

export const SET_RESULT = 'setResult'
export const SET_RESULTS = 'setResults'
export const SET_SECTIONS = 'setSections'
export const SET_TEST_ID = 'setTestId'

export const EXPORT_REPORT = 'exportReport'
export const FETCH_SECTIONS = 'fetchSections'
export const GET_RESULT = 'getResult'
export const GET_RESULTS = 'getResults'
export const SUBMIT_TEST = 'submitTest'

const db = firebase.firestore()

const state = {
  sections: [],
  results: false,
  result: false,
  testId: null
}

const mutations = {
  [SET_SECTIONS](state, sections) {
    state.sections = sections
  },
  [SET_RESULTS](state, results) {
    state.results = results
  },
  [SET_RESULT](state, result) {
    state.result = result
  },
  [SET_TEST_ID](state, testId) {
    state.testId = testId
  }
}

const actions = {
  async [FETCH_SECTIONS](context) {
    try {
      let testsRef = await db.collection('tests')
        .orderBy('createdAt', 'desc')
        .limit(1)
        .get()

      let testsDoc = testsRef.docs[0]
      if (testsDoc.exists) {
        let sections = testsDoc.data().sections
        context.commit(SET_SECTIONS, sections)
        context.commit(SET_TEST_ID, testsDoc.id)
      }
    } catch (e) {
      console.log(e)
    }

  },
  async [SUBMIT_TEST](context, answers) {
    let url = '/api/submitTest'
    let results = await axios.post(url, {
      testId: state.testId,
      answers
    })
    context.commit(SET_RESULTS, results)
    return results
  },

  async [GET_RESULT](context, id) {
    let resultRef = await db.doc(`results/${id}`).get()
    context.commit(SET_RESULT, resultRef.data())
  },

  async [GET_RESULTS](context) {
    let user = await firebase.auth().currentUser
    let ownerRef = await db.doc(`users/${user.uid}`)
    let resultsRef = await db.collection('results')
      .orderBy('createdAt', 'desc')
      .where('owner', '==', ownerRef)
      .get()
    let docs = resultsRef.docs.map(doc => {
      return {
        ...doc.data(),
        id: doc.id
      }
    })
    context.commit(SET_RESULTS, docs)
  },
  [EXPORT_REPORT](context, resultId) {
    let url = '/api/reports'
    return axios.post(url, { resultId }, { responseType: 'blob' })
  }
}

export default {
  state,
  mutations,
  actions
}
