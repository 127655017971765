<template>
  <div class='badge'
    :class='{ [`is-${color}`]: color }'>
    <slot/>
  </div>
</template>

<script>
  export default {
    props: {
      color: String
    }
  }
</script>

<style lang='scss' scoped>
  @use 'sass:map';
  @use '@/styles/variables';

  .badge {
    display: inline-flex;
    align-items: center;
    padding: 0 12px;
    border: 2px solid map.get(variables.$colors, 'primary', '500');
    height: 24px;
    box-sizing: border-box;
    color: map.get(variables.$colors, 'primary', '500');
    background-color: map.get(variables.$colors, 'gray', '300');
    border-radius: 12px;
    font-weight: map.get(variables.$font-weights, 'bold');
    font-size: map.get(variables.$font-sizes, 'sm');

    @each $name, $color in variables.$colors {
      &.is-#{$name} {
        border-color: map.get($color, '500');
        color: map.get($color, '500');
        background-color: map.get($color, '300');
      }
    }
  }

  @media (min-width: map.get(variables.$breakpoints, 'lg')) {
    .badge {
      padding: 0 16px;
      height: 32px;
      border-radius: 16px;
    }
  }
</style>
