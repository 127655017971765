<template>
  <div class='select'>
    <template v-if='label'>
      <div class='lab'
        :class='{ "is-invalid": error }'>
        <div class='ovf'>{{ label }}</div>
      </div>
    </template>
    <input class='int'
      type='text'
      :class='{ "is-invalid": error }'
      :placeholder='placeholder'
      :readonly='readonly'
      :disabled='disabled'
      :value='text'
      @focus='focus'
      @blur='blur'
      @input='text = $event.target.value'>
    <i class='ico icon'
      :class='{ "has-label": label }'>
      arrow_drop_down
    </i>
    <transition name='ops'>
      <div class='ops'
        v-show='focused'>
        <div class='con'>
          <template
            v-for='option in filteredOptions'
            :key='`${option.value}:${option.text}`'>
            <div class='opt'
              :class='{ "is-disabled": option.disabled }'
              @mousedown='$emit("update:modelValue", option.value)'>
              <slot
                name='option'
                :option='option'>
                <div class='ovf'>{{ option.text }}</div>
              </slot>
            </div>
          </template>
        </div>
      </div>
    </transition>
    <template v-if='error'>
      <div class='err'>
        <div class='ovf'>{{ error }}</div>
      </div>
    </template>
  </div>
</template>

<script>
  import { computed, ref, watchEffect } from 'vue'
  import escapeRegExp from 'lodash/escapeRegExp'

  export default {
    props: {
      label: String,
      placeholder: String,
      error: String,
      readonly: Boolean,
      disabled: Boolean,
      options: Array,
      filter: Function,
      modelValue: String
    },

    emits: [
      'focus',
      'blur',
      'update:modelValue'
    ],

    setup (props, context) {
      let focused = ref(false)
      let text = ref('')

      let filteredOptions = computed(() => {
        let pattern = new RegExp(escapeRegExp(text.value), 'i')
        return props.options.filter(option =>
          props.filter
            ? props.filter(option, pattern)
            : pattern.test(option.text)
        )
      })

      let setText = () => {
        text.value = props.options
          .find(option => option.value === props.modelValue)
          ?.text
      }

      let clearText = () => {
        text.value = ''
      }

      let focus = event => {
        focused.value = true
        clearText()
        context.emit('focus', event)
      }

      let blur = event => {
        focused.value = false
        setText()
        context.emit('blur', event)
      }

      watchEffect(() => {
        setText()
      })

      return {
        focused,
        text,
        filteredOptions,
        focus,
        blur
      }
    }
  }
</script>

<style lang='scss' scoped>
  @use 'sass:map';
  @use '@/styles/variables';

  .select {
    position: relative;
  }

  .lab {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    height: 16px;
    color: map.get(variables.$colors, 'gray', '500');
    font-size: map.get(variables.$font-sizes, 'sm');

    &.is-invalid {
      color: map.get(variables.$colors, 'red', '500');
    }
  }

  .ovf {
    overflow: hidden;
    min-width: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .int {
    padding-right: 20px;
    border-bottom: 1px solid map.get(variables.$colors, 'gray', '300');
    width: 100%;
    height: 24px;
    box-sizing: border-box;
    border-radius: 0;
    font-weight: map.get(variables.$font-weights, 'bold');

    &:focus {
      border-color: map.get(variables.$colors, 'primary', '500');

      &:not(:read-only) {
        color: map.get(variables.$colors, 'gray', '300');
      }
    }

    &:hover {
      border-color: map.get(variables.$colors, 'primary', '500');
    }

    &:disabled {
      border-color: map.get(variables.$colors, 'gray', '300');
      color: map.get(variables.$colors, 'gray', '500');
    }

    &::placeholder {
      color: map.get(variables.$colors, 'gray', '300');
    }

    &.is-invalid {
      border-color: map.get(variables.$colors, 'red', '300');
      color: map.get(variables.$colors, 'red', '500');

      &:focus,
      &:hover {
        border-color: map.get(variables.$colors, 'red', '500');
      }
    }
  }

  .ico {
    position: absolute;
    top: 0;
    right: 0;
    color: map.get(variables.$colors, 'gray', '300');
    line-height: 24px;
    pointer-events: none;

    &.has-label {
      top: 24px;
    }
  }

  .ops {
    position: absolute;
    right: 0;
    left: 0;
    z-index: map.get(variables.$elevations, 'menu');

    &-enter-active,
    &-leave-active {
      transition: opacity 0.2s ease-in-out;
    }

    &-enter-from,
    &-leave-to {
      opacity: 0;
    }
  }

  .con {
    overflow-y: auto;
    margin-bottom: 24px;
    padding: 8px 0;
    border-right: 1px solid map.get(variables.$colors, 'gray', '300');
    border-bottom: 1px solid map.get(variables.$colors, 'gray', '300');
    border-left: 1px solid map.get(variables.$colors, 'gray', '300');
    max-height: min(376px, 80vh);
    box-sizing: border-box;
    background-color: white;
  }

  .opt {
    display: flex;
    align-items: center;
    padding: 0 16px;
    height: 32px;
    cursor: pointer;

    &:hover {
      background-color: map.get(variables.$colors, 'gray', '100');
    }

    &.is-disabled {
      color: map.get(variables.$colors, 'gray', '300');
      pointer-events: none;
    }
  }

  .err {
    display: flex;
    align-items: center;
    margin-top: 8px;
    height: 16px;
    color: map.get(variables.$colors, 'red', '500');
    font-size: map.get(variables.$font-sizes, 'sm');
  }

  @media (min-width: map.get(variables.$breakpoints, 'lg')) {
    .lab {
      height: 20px;
    }

    .int {
      padding-right: 24px;
      height: 32px;
    }

    .ico {
      line-height: 32px;

      &.has-label {
        top: 28px;
      }
    }

    .con {
      margin-bottom: 32px;
      max-height: min(468px, 80vh);
    }

    .opt {
      height: 40px;
    }

    .err {
      height: 20px;
    }
  }
</style>
