import axios from '@/axios'
import firebase from '@/firebase'
import { getIndustry, getCompany } from '@/helpers/store/user'
export const SET_PROFILE = 'setProfile'
export const SET_USER = 'setUser'
export const SET_COMPANY_INDUSTRY = 'setCompanyIndustry'
export const CREATE_USER = 'createUser'
export const FETCH_PROFILE = 'fetchProfile'
export const GET_USER = 'getUser'
export const RELOAD_USER = 'reloadUser'
export const SEND_CONTACT = 'sendContact'
export const SEND_EMAIL_VERIFICATION = 'sendEmailVerification'
export const SEND_INVITATIONS = 'sendInvitations'
export const SEND_PASSWORD_RESET_EMAIL = 'sendPasswordResetEmail'
export const SIGN_IN = 'signIn'
export const SIGN_IN_AS_GUEST = 'signInAsGuest'
export const SIGN_OUT = 'signOut'
export const UPDATE_PROFILE = 'updateProfile'
export const WATCH_USER = 'watchUser'

const state = {
  user: false,
  profile: null,
  industry: false,
  company: false
}

const mutations = {
  [SET_COMPANY_INDUSTRY](state, { industry, company }) {
    state.industry = industry
    state.company = company
  },
  [SET_USER](state, user) {
    if (!user) {
      state.user = null
      return
    }

    state.user = {
      email: user.email,
      emailVerified: user.emailVerified,
      isAnonymous: user.isAnonymous
    }
  },

  [SET_PROFILE](state, profile) {
    state.profile = {
      firstName: '',
      lastName: '',
      mobileNumber: '',
      work: {
        company: '',
        industry: '',
        position: ''
      },
      ...profile
    }

  }
}

const actions = {
  [GET_USER]() {
    let user = firebase.auth().currentUser
    if (user) return user

    return new Promise(resolve => {
      let unsubscribe = firebase.auth()
        .onAuthStateChanged(user => {
          resolve(user)
          unsubscribe()
        })
    })
  },

  [WATCH_USER](context) {
    firebase.auth().onAuthStateChanged(user => {
      context.commit(SET_USER, user)
    })
  },

  [CREATE_USER](context, { email, password }) {
    return firebase.auth()
      .createUserWithEmailAndPassword(email, password)
  },

  [SIGN_IN](context, { email, password }) {
    return firebase.auth()
      .signInWithEmailAndPassword(email, password)
  },

  [SIGN_IN_AS_GUEST](context) {
    return firebase.auth().signInAnonymously();
  },

  [SIGN_OUT]() {
    return firebase.auth().signOut()
  },


  [SEND_CONTACT](context, { mobileNumber, email }) {
    let url = '/api/contactUs'
    return axios.post(url, { mobileNumber, email })
  },

  [SEND_PASSWORD_RESET_EMAIL](context, { email }) {
    return firebase.auth().sendPasswordResetEmail(email)
  },

  [SEND_EMAIL_VERIFICATION](context, { profile }) {
    let url = '/api/sendEmailVerification'
    return axios.post(url, profile)
  },

  [SEND_INVITATIONS](context, { emails }) {
    let url = '/api/sendInvitations'
    return axios.post(url, emails)
  },

  async [RELOAD_USER](context) {
    let user = firebase.auth().currentUser
    await user.reload()
    await user.getIdToken(true)
    context.commit(SET_USER, user)
  },

  async [FETCH_PROFILE](context) {
    try {

      let user = firebase.auth().currentUser
      let snapshot = await firebase.firestore()
        .doc(`users/${user.uid}`)
        .get()
      let companyId = snapshot.data()?.companyId
      let industryId = snapshot.data()?.industryId

      if (!companyId && !industryId) {
        // register with out company and industry
        let profile = {
          ...snapshot.data()?.profile,

        }
        context.commit(SET_PROFILE, profile)
      } else {
        //already have company and industry
        const [companySnap, industrySnap] = await Promise.all([
          snapshot.data().companyId.get(),
          snapshot.data().industryId.get()
        ])
        // let companySnap = await snapshot.data().companyId.get()
        // let industrySnap = await snapshot.data().industryId.get()

        const companyDetail = {
          id: companySnap.id,
          ...companySnap.data()
        }

        const industryDetail = {
          id: industrySnap.id,
          ...industrySnap.data()
        }

        const industry = getIndustry(industrySnap)
        const company = getCompany(companySnap)
        let profile = {
          ...snapshot.data()?.profile,
          industry,
          company
        }
        context.commit(SET_COMPANY_INDUSTRY, {
          industry: industryDetail,
          company: companyDetail
        })

        context.commit(SET_PROFILE, profile)


      }
    } catch (e) {
      console.log(e)
    }

  },

  async [UPDATE_PROFILE](context, { profile }) {
    let url = '/api/updateProfile'
    await axios.post(url, profile)
    await context.dispatch(FETCH_PROFILE)
  }
}

export default {
  state,
  mutations,
  actions
}
