<template>
  <label class='radio'>
    <i class='ico icon'
      :class='{
        "is-checked": checked,
        [`is-${color}`]: color
      }'>
      {{ checked ? icon ?? 'radio_button_checked' : 'radio_button_unchecked' }}
    </i>
    <slot/>
    <input class='inr'
      type='radio'
      :checked='checked'
      @change='$emit("update:modelValue", value)'>
  </label>
</template>

<script>
  import { computed } from 'vue'

  export default {
    props: {
      icon: String,
      color: String,
      value: String,
      modelValue: String
    },

    emits: ['update:modelValue'],

    setup (props) {
      let checked = computed(() => props.value === props.modelValue)
      return { checked }
    }
  }
</script>

<style lang='scss' scoped>
  @use 'sass:map';
  @use '@/styles/variables';

  .radio {
    display: inline-flex;
    cursor: pointer;
  }

  .ico {
    margin-right: 16px;
    color: map.get(variables.$colors, 'gray', '300');
    cursor: pointer;

    &.is-checked {
      color: map.get(variables.$colors, 'primary', '500');
    }

    @each $name, $color in variables.$colors {
      &.is-#{$name} {
        color: map.get($color, '300');

        &.is-checked {
          color: map.get($color, '500');
        }
      }
    }
  }

  .inr {
    display: none;
  }

  @media (min-width: map.get(variables.$breakpoints, 'lg')) {
    .ico {
      margin-right: 24px;
    }
  }
</style>
