<template>
  <div class='dialog'>
    <teleport to='body'>
      <transition name='cnt'>
        <div class='cnt'
          v-show='visible'>
          <slot/>
        </div>
      </transition>
      <transition name='ovr'>
        <div class='ovr'
          v-show='visible'
          @click='hide'>
        </div>
      </transition>
    </teleport>
  </div>
</template>

<script>
  import { ref, onUnmounted } from 'vue'

  export default {
    setup () {
      let visible = ref(false)

      let show = () => {
        if (visible.value) return
        visible.value = true
        document.body.style.overflow = 'hidden'
      }

      let hide = () => {
        if (!visible.value) return
        visible.value = false
        document.body.style.overflow = ''
      }

      onUnmounted(() => {
        hide()
      })

      return { visible, show, hide }
    }
  }
</script>

<style lang='scss' scoped>
  @use 'sass:map';
  @use '@/styles/variables';

  .dialog {
    position: absolute;
  }

  .cnt {
    position: fixed;
    top: 15%;
    right: 24px;
    left: 24px;
    z-index: map.get(variables.$elevations, 'dialog');
    overflow-y: auto;
    margin: auto;
    max-width: 600px;
    max-height: 70%;
    background-color: white;
    border-radius: 4px;

    &-enter-active,
    &-leave-active {
      transition-property: opacity, transform;
      transition-duration: 0.2s;
      transition-timing-function: ease-in-out;
    }

    &-enter-from,
    &-leave-to {
      opacity: 0;
      transform: scale(1.025);
    }
  }

  .ovr {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: map.get(variables.$elevations, 'dialog') - 1;
    background-color: rgba(map.get(variables.$colors, 'gray', '900'), 0.9);

    &-enter-active,
    &-leave-active {
      transition: opacity 0.2s ease-in-out;
    }

    &-enter-from,
    &-leave-to {
      opacity: 0;
    }
  }
</style>
