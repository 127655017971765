import firebase from '@/firebase'

export const SET_TESTIMONIALS = 'setTestimonials'

export const FETCH_TESTIMONIALS = 'fetchTestimonials'

const state = {
  testimonials: []
}

const mutations = {
  [SET_TESTIMONIALS] (state, testimonials) {
    state.testimonials = testimonials
  }
}

const actions = {
  async [FETCH_TESTIMONIALS] (context) {
    let ref = firebase.firestore().collection('testimonials')
    let snapshot = await ref.get()
    let testimonials = snapshot.docs.map(snapshot => ({
      id: snapshot.id,
      ...snapshot.data()
    }))
    context.commit(SET_TESTIMONIALS, testimonials)
  }
}

export default {
  state,
  mutations,
  actions
}
