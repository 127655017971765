import { createApp } from 'vue'
import App from './app.vue'
import elements from './elements'
import i18n from './i18n'
import router from './router'
import store from './store'
import Highcharts from 'highcharts'
import HighchartsMore from 'highcharts/highcharts-more'
import HighchartsVue from 'highcharts-vue'
HighchartsMore(Highcharts)

const app = createApp(App)
app.use(HighchartsVue, {
  highcharts: Highcharts
})
app.use(elements)
app.use(i18n)
app.use(router)
app.use(store)
app.mount('#app')
