<template>
  <div class='input'>
    <template v-if='label'>
      <div class='lab'
        :class='{ "is-invalid": error }'>
        <div class='ovf'>{{ label }}</div>
      </div>
    </template>
    <input class='inp'
      :class='{ "is-invalid": error }'
      :type='type'
      :placeholder='placeholder'
      :disabled='disabled'
      :value='modelValue'
      @focus='$emit("focus", $event)'
      @blur='$emit("blur", $event)'
      @input='$emit("update:modelValue", $event.target.value)'>
    <template v-if='error'>
      <div class='err'>
        <div class='ovf'>{{ error }}</div>
      </div>
    </template>
  </div>
</template>

<script>
  export default {
    props: {
      type: String,
      label: String,
      placeholder: String,
      error: String,
      disabled: Boolean,
      modelValue: [String, Number]
    },

    emits: [
      'focus',
      'blur',
      'update:modelValue'
    ]
  }
</script>

<style lang='scss' scoped>
  @use 'sass:map';
  @use '@/styles/variables';

  .lab {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    height: 16px;
    color: map.get(variables.$colors, 'gray', '500');
    font-size: map.get(variables.$font-sizes, 'sm');

    &.is-invalid {
      color: map.get(variables.$colors, 'red', '500');
    }
  }

  .ovf {
    overflow: hidden;
    min-width: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .inp {
    border-bottom: 1px solid map.get(variables.$colors, 'gray', '300');
    width: 100%;
    height: 24px;
    box-sizing: border-box;
    border-radius: 0;
    font-weight: map.get(variables.$font-weights, 'bold');

    &:focus,
    &:hover {
      border-color: map.get(variables.$colors, 'primary', '500');
    }

    &:disabled {
      border-color: map.get(variables.$colors, 'gray', '300');
      color: map.get(variables.$colors, 'gray', '500');
    }

    &::placeholder {
      color: map.get(variables.$colors, 'gray', '300');
    }

    &.is-invalid {
      border-color: map.get(variables.$colors, 'red', '300');
      color: map.get(variables.$colors, 'red', '500');

      &:focus,
      &:hover {
        border-color: map.get(variables.$colors, 'red', '500');
      }
    }
  }

  .err {
    display: flex;
    align-items: center;
    margin-top: 8px;
    height: 16px;
    color: map.get(variables.$colors, 'red', '500');
    font-size: map.get(variables.$font-sizes, 'sm');
  }

  @media (min-width: map.get(variables.$breakpoints, 'lg')) {
    .lab {
      height: 20px;
    }

    .inp {
      height: 32px;
    }

    .err {
      height: 20px;
    }
  }
</style>
