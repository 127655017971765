export const getIndustry = (industrySnap) => {
    const industry = industrySnap.data()
    if (!industry) {
        return {
            value: '',
            id: ''
        }
    }
    return {
        id: industry.isMaster ? industrySnap.id : '*',
        value: industrySnap.data().name
    }
}

export const getCompany = (companySnap) => {
    const company = companySnap.data()

    if (!company) {
        return {
            id: '',
            value: ''
        }
    }
    return {
        id: companySnap.id,
        value: companySnap.data().name
    }
}