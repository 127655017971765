<template>
  <component :is="layout" />
</template>

<script>
import { computed, defineAsyncComponent } from "vue";
import { useRoute } from "vue-router";
export default {
  setup() {
    const dataLayer = window.dataLayer;
    let route = useRoute();
    let pageName = computed(() => route.name);
    let name = computed(() => route.meta?.layout?.name ?? "main");
    let layout = computed(() =>
      defineAsyncComponent(() =>
        import(`@/layouts/${name.value}/${name.value}.vue`)
      )
    );
    const beforeUnload = () => {
      dataLayer.push({
        event: "drop_off_page",
        page: pageName.value,
      });
    };
    window.addEventListener("beforeunload", beforeUnload);

    return { layout };
  },
};
</script>

<style lang="scss">
@use '@/styles/fonts';
@use '@/styles/icons';
@use '@/styles/reboot';
</style>
