import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      layout: {
        name: 'main',
        header: true
      }
    },
    component: () => import('@/pages/home/home.vue')
  },
  {
    path: '/invitation',
    name: 'invitation',
    meta: {
      layout: {
        name: 'main',
        header: true
      }
    },
    component: () => import('@/pages/invitation/invitation.vue')
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      layout: {
        name: 'main',
        header: true
      }
    },
    component: () => import('@/pages/login/login.vue')
  },
  {
    path: '/maturity-analysis',
    name: 'maturity-analysis',
    meta: {
      layout: {
        name: 'main',
        header: true
      }
    },
    component: () => import('@/pages/maturity-analysis/maturity-analysis.vue')
  },
  {
    path: '/me/profile',
    name: 'profile',
    meta: {
      layout: {
        name: 'main',
        header: true
      }
    },
    component: () => import('@/pages/profile/profile.vue')
  },
  {
    path: '/me/result/:id',
    name: 'result',
    meta: {
      layout: {
        name: 'main',
        header: true
      }
    },
    component: () => import('@/pages/result/result.vue')
  },
  {
    path: '/me/results',
    name: 'results',
    meta: {
      layout: {
        name: 'main',
        header: true
      }
    },
    component: () => import('@/pages/results/results.vue')
  },
  {
    path: '/register',
    name: 'register',
    meta: {
      layout: {
        name: 'main',
        header: true
      }
    },
    component: () => import('@/pages/register/register.vue')
  },
  {
    path: '/register-guest',
    name: 'register-guest',
    meta: {
      layout: {
        name: 'main',
        header: true
      }
    },
    component: () => import('@/pages/register/register-guest.vue')
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    meta: {
      layout: {
        name: 'main',
        header: true
      }
    },
    component: () => import('@/pages/reset-password/reset-password.vue')
  },
  {
    path: '/start',
    name: 'start',
    meta: {
      layout: {
        name: 'main',
        header: true
      }
    },
    component: () => import('@/pages/start/start.vue')
  },
  {
    path: '/test',
    name: 'test',
    meta: {
      layout: {
        name: 'main',
        header: true
      }
    },
    component: () => import('@/pages/test/test.vue')
  }
]

export default createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior (to, from, savedPosition) {
    return savedPosition ?? { top: 0 }
  }
})
