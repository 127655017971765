import axios from 'axios'
import firebase from '@/firebase'

const getIdToken = (() => {
  let forceRefresh = true
  return async user => {
    let token = await user.getIdToken(forceRefresh)
    forceRefresh = false
    return token
  }
})()

axios.interceptors.request.use(async config => {
  let user = firebase.auth().currentUser
  if (user) {
    let token = await getIdToken(user)
    config.headers['Authorization'] = `Bearer ${token}`
  }
  return config
})

export default axios
