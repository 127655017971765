<template>
  <label class='checkbox'>
    <i class='ico icon'
      :class='{
        "is-checked": modelValue,
        [`is-${color}`]: color
      }'>
      {{ modelValue ? 'check_box' : 'check_box_outline_blank' }}
    </i>
    <slot/>
    <input class='inc'
      type='checkbox'
      :checked='modelValue'
      @change='$emit("update:modelValue", $event.target.checked)'>
  </label>
</template>

<script>
  export default {
    props: {
      color: String,
      modelValue: Boolean
    },

    emits: ['update:modelValue']
  }
</script>

<style lang='scss' scoped>
  @use 'sass:map';
  @use '@/styles/variables';

  .checkbox {
    display: inline-flex;
    cursor: pointer;
  }

  .ico {
    margin-right: 16px;
    color: map.get(variables.$colors, 'gray', '300');
    cursor: pointer;

    &.is-checked {
      color: map.get(variables.$colors, 'primary', '500');
    }

    @each $name, $color in variables.$colors {
      &.is-#{$name} {
        color: map.get($color, '300');

        &.is-checked {
          color: map.get($color, '500');
        }
      }
    }
  }

  .inc {
    display: none;
  }

  @media (min-width: map.get(variables.$breakpoints, 'lg')) {
    .ico {
      margin-right: 24px;
    }
  }
</style>
